/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Link,
  Tooltip,
  TableHead,
  Box,
} from "@mui/material";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import StarIcon from "@mui/icons-material/Star";
import { SpeciesReportType } from "../../types/DataTypes";
import { handleNavigateWebsite } from "../../utils/handleNavigateWebsite";
import { SpeciesListCardPropsType } from "../../types/PropsTypes";
import StarsRating from "../../components/starsRating/StarsRating";

function SpeciesListCard({
  data,
  visible,
  setVisible,
}: SpeciesListCardPropsType) {
  const handleLoadMore = () => {
    setVisible(visible + 10);
  };
  const {
    site_details: { unique_at_site_species: uniqueSpecies },
  } = data;

  const isSpecieUnique = (latinName: string) => {
    return uniqueSpecies?.includes(latinName) ? `${latinName}*` : latinName;
  };

  const isSpecieVertivore = (isVertivore: boolean) => {
    return isVertivore ? "†" : "";
  };
  return (
    <Grid item xs={12} md={8} pt={3}>
      <TableContainer component={Paper}>
        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center">Latin name</TableCell>
                  <TableCell align="center">English name</TableCell>
                  <TableCell align="center">Species in selected days</TableCell>
                  <TableCell align="center">Bird song</TableCell>
                  <TableCell align="center">Rating</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.report.species
                  ?.slice(0, visible)
                  .map((species: SpeciesReportType, index) => {
                    return (
                      <TableRow
                        key={species.latin}
                        sx={{
                          "&:nth-of-type(even)": {
                            backgroundColor: "rgba(206, 133, 20, 0.12)",
                          },
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: species.is_endangered ? "#FF0000" : "#000",
                            fontWeight: species.is_endangered
                              ? "bold"
                              : "normal",
                          }}
                        >
                          {`${isSpecieUnique(species.latin)}${isSpecieVertivore(
                            species.is_vertivore
                          )}`}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: species.is_endangered ? "#FF0000" : "#000",
                            fontWeight: species.is_endangered
                              ? "bold"
                              : "normal",
                          }}
                        >
                          {species.english}
                        </TableCell>
                        <TableCell align="center">{species.n}</TableCell>
                        {species.song_url ? (
                          <TableCell align="center">
                            <Tooltip title="Bird song" arrow>
                              <IconButton
                                onClick={() =>
                                  handleNavigateWebsite(species.song_url)
                                }
                              >
                                <MusicNoteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}
                        <TableCell
                          align="center"
                          sx={{
                            width: "180px",
                          }}
                        >
                          <StarsRating rating={species.rating} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </Box>
        {visible < data.report.species.length && (
          <Link
            sx={{ padding: 1 }}
            component="button"
            variant="body1"
            onClick={handleLoadMore}
          >
            Load More...
          </Link>
        )}
      </TableContainer>
    </Grid>
  );
}
export default SpeciesListCard;
