/* eslint-disable camelcase */
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import { Chip, Divider } from "@mui/material";
import { useRef, useState } from "react";
import ReportCard from "../../components/cards/ReportCard";
import "chart.js/auto";
import { FarmReportResponseType } from "../../types/DataTypes";
import SpeciesListCard from "./SpeciesListCard";
import PdfComponent from "../../components/pdfComponent/PdfComponent";
import PdfComponentSpeciesList from "../../components/pdfComponent/PdfComponentSpeciesList";
import DescriptionComponent from "../../components/descriptionComponent/DescriptionComponent";
import { DESCRIPTION_CONSTANTS } from "../../constants/descriptionConstants";
import RecordingSiteDetails from "./report1/RecordingSiteDetails";
import FarmBasicInfo from "./report1/FarmBasicInfo";
import RecordingSitesBasicInfo from "./report1/RecordingSitesBasicInfo";
import ChirrupRating from "./report1/ChirrupRating";
import TrendChart from "./report1/TrendChart";
import SpeciesPieChart from "./report1/SpeciesPieChart";
import SpeciesDetails from "./report1/SpeciesDetails";
import CsvComponent from "../../components/csvComponent/CsvComponent";

function FarmReportCard({ data }: { data: FarmReportResponseType }) {
  const [visible, setVisible] = useState(10);
  const refDiv = useRef(null) as unknown as any;
  const handleClick = () =>
    refDiv?.current.scrollIntoView({ behavior: "smooth" });
  const pdfRef = useRef(null);
  const pdfRef2 = useRef(null);
  const content: HTMLElement = pdfRef.current as unknown as HTMLElement;
  const content2: HTMLElement = pdfRef2.current as unknown as HTMLElement;

  return (
    <>
      <Box ref={pdfRef}>
        <Grid container spacing={2} maxWidth="100%" alignContent="center">
          <Grid item sm={12} md={12} lg={12}>
            <Divider>
              <Chip color="primary" label="Farm details:" />
            </Divider>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} width="100%">
            <FarmBasicInfo {...data.farm} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} width="100%">
            <ChirrupRating {...data.chirrup_rating} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} width="100%">
            <TrendChart trend={data.trend} farm={data.farm} />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Divider>
              <Chip color="primary" label="Recording site(s) details:" />
            </Divider>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
          >
            <Box sx={{ width: "100%", maxWidth: { lg: "50%" } }}>
              <RecordingSitesBasicInfo
                recordingSites={data.farm.recording_sites}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <RecordingSiteDetails
              siteDetails={data.site_details}
              sx={{ height: { md: "60%" }, minHeight: "auto" }}
            />
            <DescriptionComponent
              htmlString={DESCRIPTION_CONSTANTS.thirdCard}
            />
          </Grid>

          <Grid item sm={12} md={6} lg={4} width="100%">
            <SpeciesPieChart species={data.report.species} />
            <DescriptionComponent
              htmlString={DESCRIPTION_CONSTANTS.firstCard}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4} width="100%">
            <SpeciesDetails
              species={data.report.species}
              onClick={handleClick}
            />
            <DescriptionComponent
              htmlString={DESCRIPTION_CONSTANTS.secondCard}
            />
          </Grid>
        </Grid>
      </Box>
      <PdfComponent content={content} />
      <Box p={5} ref={refDiv} />
      <Grid item xs={12} md={12}>
        <Box ref={pdfRef2}>
          <ReportCard title="Species list">
            <Box display="flex" justifyContent="center">
              <SpeciesListCard
                data={data}
                visible={visible}
                setVisible={setVisible}
              />
            </Box>
          </ReportCard>
        </Box>
        <PdfComponentSpeciesList
          content={content2}
          setVisible={setVisible}
          data={data}
        />
      </Grid>
      <CsvComponent data={data} />
    </>
  );
}

export default FarmReportCard;
