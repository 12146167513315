import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { styleUpload } from "../../styles/customStyles";
import FileUploadErrorCard from "../cards/FileUploadErrorCard";
import { UploadError } from "../../utils/uploadFiles";

function UploadErrorModal({
  openModal,
  onClose,
  errors,
}: {
  openModal: boolean;
  onClose: () => void;
  errors: UploadError[];
}) {
  return (
    <Modal
      open={openModal}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleUpload}>
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={onClose}
        >
          <HighlightOffIcon />
        </IconButton>
        <Box display="flex" justifyContent="space-around">
          <Box textAlign="center">
            <Typography variant="h5">Error</Typography>
            <Typography variant="body1" marginY={1}>
              Something went wrong with your file upload.
            </Typography>
            <Typography variant="body1" marginBottom={1}>
              Please try again.
            </Typography>
            {errors.map((error: UploadError) => (
              <FileUploadErrorCard
                key={error.fileName}
                code={error.code}
                sevirity={error.sevirity}
                fileName={error.fileName}
              />
            ))}
            <Box textAlign="center" position="relative" mt={2}>
              <Button
                color="primary"
                variant="contained"
                style={{ zIndex: 20, margin: "50", alignItems: "center" }}
                type="button"
                onClick={onClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default UploadErrorModal;
