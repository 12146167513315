import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { REACT_APP_API_URL } from "../../../constants/apiConstants";
import { useAuth } from "../../../context/AuthContext";
import useFetch from "../../../service/useFetch";
import { FarmSelectionObject } from "../../../types/PropsTypes";
import { requestHeaderToken } from "../../../utils/requestHeaderToken";
import {
  SpeciesComparisonReportDataType,
  YearToYearReportDataType,
} from "../../../types/DataTypes";
import BackComponent from "../../../components/back/BackComponent";
import InputAutoComplete from "../../../components/input/InputAutoComplete";
import SpeciesComparisonBarChart from "./SpeciesComparisonBarChart";
import useMutate from "../../../service/useMutate";

function YearToYearReportPage() {
  const { token, user } = useAuth();
  const [report, setReport] = useState<SpeciesComparisonReportDataType | null>(
    null
  );
  const [activeReportData, setActiveReportData] =
    useState<YearToYearReportDataType | null>(null);
  const [farm, setFarm] = useState<string>("");
  const [year1, setYear1] = useState<number>(dayjs().year());
  const [year2, setYear2] = useState<number>(dayjs().year() - 1);
  const isYearDisabled = (year: number, selectedYear: number) => {
    return year === selectedYear;
  };
  const [recordingSite, setRecordingSite] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const accessType = user?.role === "ORGANIZATION_ADMIN" ? "limited" : "all";
  const { responseData, loading, error } = useFetch<FarmSelectionObject[]>({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/role-filtered?access_type=${accessType}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const { fetchData: fetchReport } = useMutate({
    url: `${REACT_APP_API_URL}/recording-sites/year-to-year-report`,
    method: "POST",
    headers: requestHeaderToken(token),
  });

  const farmLookup = useMemo(() => {
    if (responseData)
      return Object.fromEntries(responseData.map((farm) => [farm.id, farm]));
    return {};
  }, [responseData]);

  const handleChange = (event: any, newValue: any) => {
    setFarm(newValue?.id ?? "");
    setRecordingSite(null);
    setYear1(dayjs().year());
    setYear2(dayjs().year() - 1);
    setReport(null);
  };
  const handleRSChange = (newSite: { id: string; name: string } | null) => {
    setRecordingSite(newSite);
    setYear1(dayjs().year());
    setYear2(dayjs().year() - 1);
    setReport(null);
  };

  const onSubmit = () => {
    console.log(year1, year2);
    if (farm && recordingSite && year1 && year2) {
      setActiveReportData({
        farm,
        recording_site: recordingSite.id,
        year1,
        year2,
      });
      setReport(null);
      setIsReportLoading(true);
    } else {
      setActiveReportData(null);
      setReport(null);
      setIsReportLoading(false);
    }
  };
  const fetchCallback = useCallback(async () => {
    if (activeReportData) {
      await fetchReport(activeReportData).then(setReport);
      setIsReportLoading(false);
    }
  }, [activeReportData, fetchReport]);

  useEffect(() => {
    fetchCallback();
  }, [fetchCallback]);
  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {error && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <BackComponent />
            <Typography variant="h4" color="error">
              {error?.statusCode} {"  "}
              {error?.error}
            </Typography>
          </Box>
        )}
        <Grid container spacing={2} direction="row" margin={0}>
          <Grid item xs={12}>
            {!loading && responseData && (
              <Grid spacing={2} container justifyContent="flex-end">
                <Grid item xs={12} md={4} lg={4}>
                  <InputAutoComplete
                    value={null}
                    label="Farm"
                    onChange={handleChange}
                    options={responseData ?? []}
                    getOptionLabel={(farm) =>
                      (farm as FarmSelectionObject).name ?? ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Autocomplete
                    size="small"
                    id="site_id"
                    value={farm ? recordingSite : null}
                    options={
                      farm ? farmLookup[farm]?.recording_sites || [] : []
                    }
                    filterOptions={(option) => option}
                    getOptionLabel={(rs: any) => farm && `${rs.name}`}
                    onChange={(e, value) => handleRSChange(value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Site" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <DatePicker
                    views={["year"]}
                    openTo="year"
                    label="Year 1"
                    value={dayjs(`${year1}-01-01`)}
                    onChange={(newValue) => {
                      if (newValue && newValue.isValid()) {
                        setYear1(newValue.year());
                      }
                    }}
                    shouldDisableDate={(date) =>
                      isYearDisabled(date.year(), year2)
                    }
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={null}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <DatePicker
                    views={["year"]}
                    openTo="year"
                    label="Year 2"
                    value={dayjs(`${year2}-01-01`)}
                    onChange={(newValue) => {
                      if (newValue && newValue.isValid()) {
                        setYear2(newValue.year());
                      }
                    }}
                    shouldDisableDate={(date) =>
                      isYearDisabled(date.year(), year1)
                    }
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={null}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <Button
                    onClick={() => onSubmit()}
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    Compare sites
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {(!farm || !recordingSite || !year1 || !year2) && (
                    <Alert severity="info">
                      Please choose farm, recording site and years to compare.
                    </Alert>
                  )}
                  {isReportLoading ? (
                    <Box
                      sx={{
                        display: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    report &&
                    year1 &&
                    year2 && (
                      <SpeciesComparisonBarChart
                        reportData={report}
                        label1={year1.toString()}
                        label2={year2.toString()}
                      />
                    )
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Container>
  );
}

export default YearToYearReportPage;
