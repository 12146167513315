import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";

import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { notify } from "../../utils/notify";
import { validationSchemaCreateFarm } from "../../validation/validationSchemaCreateFarm";
import { FarmData } from "../../types/DataTypes";
import FarmBasicInfoForm from "./FarmBasicInfoForm";
import BackComponent from "../../components/back/BackComponent";

export default function FarmCreate() {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [fundingOrganizations, setFundingOrganizations] = useState<string[]>(
    []
  );
  const [farmId, setFarmId] = useState<string>("");
  const { loading, fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/farms`,
    method: "POST",
    headers: requestHeaderToken(token),
  });
  const { fetchData: sendRequestData } = useMutate({
    url: `${REACT_APP_API_URL}/farms/${farmId}/share-request-funding-organizations`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });
  const initialValues: FarmData = {
    name: "",
    CPH_number: "",
    SBI_number: "",
    address: "",
    county: "",
    post_code: "",
    is_active: true,
    main_interest: "curious-about-birds-on-farm",
  };

  const onSubmit = (values: FarmData) => {
    fetchData(values)
      .then((response) => {
        const createdFarmId = response.id;
        setFarmId(createdFarmId);
        notify("Farm successfully created");

        if (!(fundingOrganizations.length > 0)) {
          navigate("/farms");
        }
      })
      .catch((error) => {
        notify(`Something went wrong: ${error.message}`, error);
      });
  };

  useEffect(() => {
    if (farmId && fundingOrganizations.length > 0) {
      sendRequestData({
        farm_id: farmId,
        ids: fundingOrganizations.filter(Boolean),
      })
        .then(() => {
          notify("Funding relationship successfully created");
          navigate("/farms");
        })
        .catch((error) => {
          notify(
            `Something went wrong with creating funding relationship: ${error.message}. Please contact admin.`,
            error
          );
        });
    }
  }, [farmId, fundingOrganizations, navigate, sendRequestData]);

  return (
    <>
      <BackComponent />
      <Card
        sx={{
          maxWidth: { md: "50vw" },
          ml: { md: "auto" },
          mr: { md: "auto" },
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="h5">Create new farm</Typography>
            <Box>
              <FarmBasicInfoForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                fundingOrganizations={fundingOrganizations}
                setFundingOrganizations={setFundingOrganizations}
              />
            </Box>
          </CardContent>
        )}
      </Card>
    </>
  );
}
