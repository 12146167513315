import React, { ReactNode, useState } from "react";

import { useNavigate, useLocation } from "react-router";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton/IconButton";
import Typography from "@mui/material/Typography/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { Button, Divider, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import logoTransparent from "../../assets/images/logoTransparent.png";
import TitleComponent from "../title/TitleComponent";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import {
  MenuItem,
  PaginationWithSort,
  ShareRequestsResponseType,
} from "../../types/DataTypes";
import { COMPONENTS } from "../../constants/menuSideBarConstants";
import ItemForSideMenu from "./ItemForSideMenu";
import Disclaimer from "../disclaimer/Disclaimer";
import ProcedureNotesModal from "../modal/ProcedureNotesModal";
import Version from "../version/Version";

const drawerWidth = 240;

export default function NavbarComponent({ children }: { children: ReactNode }) {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [procedureNotesOpen, setProcedureNotesOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const showProcedureNotes: boolean = location.pathname.includes("reports");
  const { logout, user, token } = useAuth();
  const { responseData } = useFetch<
    PaginationWithSort<ShareRequestsResponseType>
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/share-requests?status=PENDING`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(user),
  });
  const numberOfRequests = responseData?.metadata?.count || 0;
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    setMobileOpen(false);
  };

  const handleNavigateProfile = () => {
    navigate("/user");
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  if (!user) return <p>No user data</p>;
  const drawer = (
    <div>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex">
            <img src={logoTransparent} width="50px" height="40px" alt="logo" />
            <TitleComponent
              sx={{ paddingLeft: "10px" }}
              title="Chirrup.ai"
              variant="h4"
              align="center"
            />
          </Box>
          <Box display="flex">
            <Tooltip title="Profile" arrow>
              <IconButton onClick={handleNavigateProfile}>
                <PersonIcon sx={{ color: "#ffff" }} />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" />
            <Tooltip title="Logout" arrow>
              <IconButton onClick={handleLogout}>
                <LogoutIcon sx={{ color: "#ffff" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar disableGutters />
      <Box sx={{ overflow: "auto", width: "100%" }}>
        <List dense>
          {COMPONENTS.map((item: MenuItem, index: number) => {
            return (
              <ItemForSideMenu
                key={index}
                item={item}
                userRole={user.role}
                badge={
                  item.textByRole.default === "Share requests"
                    ? numberOfRequests
                    : null
                }
              />
            );
          })}
        </List>
      </Box>
    </div>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h4" noWrap component="div">
            Chirrup.ai
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        mt={10}
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
          padding: "40px 40px 0",
          minHeight: "87vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <div>{children}</div>
        <Toolbar disableGutters />
        <div style={{ textAlign: "center" }}>
          {showProcedureNotes && (
            <ProcedureNotesModal
              open={procedureNotesOpen}
              handleClose={() => setProcedureNotesOpen(false)}
            >
              <Button
                variant="contained"
                sx={{ margin: "20px auto" }}
                onClick={() => setProcedureNotesOpen(true)}
              >
                Procedure Notes
              </Button>
            </ProcedureNotesModal>
          )}
          <Disclaimer />
          <Version />
        </div>
      </Box>
    </Box>
  );
}
