import React from "react";
import { Line } from "react-chartjs-2";
import { Box, Typography } from "@mui/material";
import ReportCard from "../../../components/cards/ReportCard";
import { theme } from "../../../styles/useTheme";
import {
  ReportFarmDataType,
  TrendDataType,
  TrendType,
} from "../../../types/DataTypes";

export default function TrendChart({
  trend,
  farm,
}: {
  trend: TrendType;
  farm: ReportFarmDataType;
}) {
  const { benchmark_group: benchmarkTrend, farm: farmTrend } = trend;
  const {
    name: farmName,
    benchmark_group: { name: benchmarkName },
  } = farm;
  const objectExists = (trendData: TrendDataType) =>
    Boolean(Object.keys(trendData).length);
  const farmTrendExists = objectExists(farmTrend);
  const benchmarkTrendExists = objectExists(benchmarkTrend);

  const generateLabels = () => {
    // make array with unique values from both trends, all years together then remove duplicates
    const trendKeys = new Set([
      ...Object.keys(farmTrend),
      ...Object.keys(benchmarkTrend),
    ]);
    const labels = Array.from(trendKeys).sort();
    // add first and last label to be for 1 smaller/larger than the smallest/largest label
    const firstLabel = Number(labels[0]) - 1;
    const lastLabel = Number(labels[labels.length - 1]) + 1;
    labels.unshift(firstLabel.toString());
    labels.push(lastLabel.toString());
    return labels;
  };

  const generateDataSet = (data: TrendDataType) => {
    const labels = generateLabels();
    if (!objectExists(data)) return [];
    const years = Object.keys(data);
    const dataset = labels.map((label) => {
      // Check if `data` contains a value for the current label (year)
      return years.includes(label) ? data[label] : null;
    });
    return dataset;
  };

  // https://www.chartjs.org/docs/latest/samples/line/segments.html
  // skipped is used to define style of line that joins the values between which there is no data
  // can be used for dash pattern, color of dashed line, etc.
  const skipped = (ctx: any, value: any) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;

  // generating dataset array only if farm trend or benchmark trend exist
  const checkDatasets = () => {
    const datasets = [];
    const farmData = generateDataSet(farmTrend);
    const benchmarkData = generateDataSet(benchmarkTrend);
    const farmDataSet = {
      label: farmName,
      data: farmData,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      spanGaps: true,
      segment: {
        borderDash: (ctx: any) => skipped(ctx, [5, 5]),
      },
      pointRadius: farmData.map((value) => (value === null ? 0 : 4)),
    };
    const benchmarkDataset = {
      label: benchmarkName,
      data: benchmarkData,
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.light,
      spanGaps: true,
      segment: {
        borderDash: (ctx: any) => skipped(ctx, [5, 5]),
      },
      pointRadius: benchmarkData.map((value) => (value === null ? 0 : 4)),
    };
    if (farmTrendExists) datasets.push(farmDataSet);
    if (benchmarkTrendExists) datasets.push(benchmarkDataset);
    return datasets;
  };
  // options and data for chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxis: {
        min: 0,
      },
    },
    maintainAspectRatio: false,
  };
  const data = {
    labels: generateLabels(),
    datasets: checkDatasets(),
  };
  const content = () => {
    if (!farmTrendExists && !benchmarkTrendExists) {
      return (
        <Typography variant="h6" textAlign="center">
          There is not enough data to calculate trends
        </Typography>
      );
    }
    return (
      <>
        <Typography variant="subtitle1" textAlign="center">
          {!farmTrendExists &&
            "There is not enough data to calculate trend for your farm"}
          {!benchmarkTrendExists &&
            "There is not enough data to calculate trend for benchmark group"}
        </Typography>
        <Box>
          <Line options={options} data={data} />
        </Box>
      </>
    );
  };

  return <ReportCard title="Trend">{content()}</ReportCard>;
}
