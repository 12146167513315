import { useEffect, useState } from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/AuthContext";
import { styleModal } from "../../styles/customStyles";
import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { notify } from "../../utils/notify";
import { ErrorTypes } from "../../types/DataTypes";

function AddMoreFarmsModal({ bgId, farms }: { bgId: string; farms: string[] }) {
  const [open, setOpen] = useState<boolean>(false);
  const { token } = useAuth();
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);
  const handleNavigate = () => {
    navigate(-1);
  };
  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/benchmark-groups/${bgId}/add-farms`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });
  const handleOpen = () => {
    setOpen(true);

    const farmIds = farms;

    fetchData({ farmIds })
      .then(() => {
        notify("Farms successfully updated!");
      })
      .catch((error: ErrorTypes) => {
        notify("Request failed!", error);
      });
  };

  return (
    <>
      <Button
        sx={{ width: "35%" }}
        type="button"
        size="medium"
        variant="contained"
        onClick={handleOpen}
      >
        Select
      </Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleClose}
          >
            <HighlightOffIcon />
          </IconButton>
          <Box p={1} textAlign="center">
            <Typography variant="h5">Add more farms?</Typography>
          </Box>
          <Box display="flex" justifyContent="space-around" marginTop={5}>
            <Button variant="contained" onClick={handleClose}>
              Yes
            </Button>
            <Button variant="contained" onClick={handleNavigate}>
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default AddMoreFarmsModal;
