/* eslint-disable jsx-a11y/anchor-is-valid */
import Grid from "@mui/material/Grid/Grid";
import { FarmReportResponseType } from "../../types/DataTypes";
import ButtonComponent from "../button/ButtonComponent";

function CsvComponent({ data }: { data: FarmReportResponseType }) {
  const generateCSV = async () => {
    const csvRows = [];

    csvRows.push(["Farm Name", "Address", "County", "Post Code"]);
    csvRows.push([
      `"${data.farm.name}"`,
      `"${data.farm.address}"`,
      `"${data.farm.county}"`,
      data.farm.post_code,
    ]);

    csvRows.push([]);

    csvRows.push(["Monitoring", "Top quartile", "Improved"]);
    csvRows.push([
      data.chirrup_rating.monitoring,
      data.chirrup_rating.top_quartile,
      data.chirrup_rating.improved,
    ]);

    csvRows.push([]);

    csvRows.push(["Year", "Benchmark group", "Farm"]);
    const years = new Set([
      ...Object.keys(data.trend.farm),
      ...Object.keys(data.trend.benchmark_group),
    ]);

    Array.from(years)
      .sort()
      .forEach((year) => {
        const farmValue =
          data.trend.farm[year] !== undefined ? data.trend.farm[year] : "";
        const benchmarkValue =
          data.trend.benchmark_group[year] !== undefined
            ? data.trend.benchmark_group[year]
            : "";

        csvRows.push([year, benchmarkValue.toString(), farmValue.toString()]);
      });

    csvRows.push([]);

    csvRows.push([
      "Recording site(s)",
      "Species",
      "Red list species",
      "Species not found elsewhere in the benchmark group of farms",
      "Total species as a percentage of all species within benchmark group of farms",
    ]);
    csvRows.push([
      `"${data.farm.recording_sites.map((site) => site.name)}"`,
      data.site_details.species_present,
      data.site_details.red_list,
      data.site_details.unique_at_site,
      data.site_details.species_percent,
    ]);

    csvRows.push([]);

    csvRows.push(["Latin Name", "English Name", "Count", "Rating"]);
    data.report.species.forEach(({ latin, english, n, rating }) => {
      csvRows.push([latin, english, n, rating]);
    });

    const csvString = csvRows.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "farmReport.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid
      item
      md={12}
      xs={12}
      p={2}
      sx={{ display: { xs: "none", xl: "block", sm: "block" } }}
    >
      <ButtonComponent
        title="Download all data to CSV file"
        type="button"
        onClick={generateCSV}
        variant="contained"
      />
    </Grid>
  );
}

export default CsvComponent;
