import React from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";

import FarmAdditionalInfoForm from "./FarmAdditionalInfoForm";
import BackComponent from "../../components/back/BackComponent";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { notify } from "../../utils/notify";
import { FullFarmData } from "../../types/DataTypes";

export default function FarmEditAdditionalInfo() {
  const { token } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const { responseData, loading } = useFetch<FullFarmData>({
    url: `${REACT_APP_API_URL}/farms/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/farms/${id}`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });
  const {
    name,
    CPH_number: cphNumber,
    SBI_number: sbiNumber,
    address,
    county,
    post_code: postCode,
    is_active: isActive,
    main_interest: mainInterest,
    additional_data: additionalData,
  } = responseData || {};
  const initialValues: FullFarmData = {
    name: name || "",
    CPH_number: cphNumber || "",
    SBI_number: sbiNumber || "",
    address: address || "",
    county: county || "",
    post_code: postCode || "",
    is_active: isActive || true,
    main_interest: mainInterest || "curious-about-birds-on-farm",
    additional_data: {
      farm_size: additionalData?.farm_size || 0,
      sustainability_systems: additionalData?.sustainability_systems || [],
      retail_suppliers: additionalData?.retail_suppliers || [],
      dairy_suppliers: additionalData?.dairy_suppliers || [],
      meat_suppliers: additionalData?.meat_suppliers || [],
      beverage_suppliers: additionalData?.beverage_suppliers || [],
      livestock_stocking_density:
        { ...additionalData?.livestock_stocking_density } || {},
      natural_water: { ...additionalData?.natural_water } || {},
      land_usage: {
        natural_grass: additionalData?.land_usage.natural_grass || 0,
        grass_seed_mix: additionalData?.land_usage.grass_seed_mix || 0,
        planted_herbal_ley: additionalData?.land_usage.planted_herbal_ley || 0,
        silvopasture: additionalData?.land_usage.silvopasture || 0,
        forage_or_crops: additionalData?.land_usage.forage_or_crops || 0,
        arable: additionalData?.land_usage.arable || 0,
        left_for_nature: additionalData?.land_usage.left_for_nature || 0,
        other: (additionalData ? +additionalData.land_usage.other : 0) || 0,
      },
    },
  };
  const onSubmit = (values: FullFarmData) => {
    fetchData(values)
      .then(() => {
        notify("Farm successfully edited!");
        navigate(`/farms/${id}`);
      })
      .catch((error) => {
        notify("Something went wrong", error);
      });
  };

  return (
    <>
      <BackComponent />
      <Card
        sx={{
          ml: { md: "auto" },
          mr: { md: "auto" },
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignItems: "center",
              mt: 2,
            }}
          >
            <Box>
              <Typography variant="h5">
                Additional farm information for {responseData?.name}
              </Typography>
              <Divider />
            </Box>
            <FarmAdditionalInfoForm
              initialValues={initialValues}
              onSubmit={onSubmit}
            />
          </CardContent>
        )}
      </Card>
    </>
  );
}
