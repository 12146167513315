import { ColorStatus } from "../types/DataTypes";

export const colorStatus: ColorStatus = {
  APPROVED: "success",
  REJECTED: "error",
  REVOKED: "default",
  PENDING: "primary",
  APPROVED_LIMITED: "success",
  FUNDING: "success",
};
