/* eslint-disable new-cap */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Grid from "@mui/material/Grid/Grid";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Link from "@mui/material/Link/Link";
import { useEffect, useState } from "react";
import { checkWebPSupport } from "../../utils/checkWebPSupport";

function PdfComponent({ content }: any) {
  const [supportsWebP, setSupportsWebP] = useState<boolean>(false);
  useEffect(() => {
    checkWebPSupport("lossy").then((isSupported) => {
      setSupportsWebP(isSupported);
    });
  }, []);
  const savePdf = async () => {
    const data = await html2canvas(content, {
      scale: 2,
      width: content.clientWidth,
      height: content.clientHeight,
    });
    const imgFormat = supportsWebP ? "image/webp" : "image/png";
    const img = data.toDataURL(imgFormat);

    const pdf = new jsPDF("landscape", "pt", "a2", true);
    const pdfWidth = content?.clientWidth;
    const pdfHeight = content?.clientHeight;
    pdf.addImage(img, imgFormat.toUpperCase(), 0, 0, pdfWidth, pdfHeight);
    pdf.save("Report.pdf");
  };

  return (
    <Grid
      item
      md={12}
      xs={12}
      p={2}
      sx={{ display: { xs: "none", xl: "block", sm: "block" } }}
    >
      <Link component="button" variant="body2" onClick={savePdf}>
        Save as PDF
      </Link>
    </Grid>
  );
}

export default PdfComponent;
