import React from "react";
import { useParams } from "react-router";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import {
  AdditionalSpeciesDataType,
  PathParams,
} from "../../../types/PropsTypes";
import useFetch from "../../../service/useFetch";
import { REACT_APP_API_URL } from "../../../constants/apiConstants";
import { requestHeaderToken } from "../../../utils/requestHeaderToken";
import { useAuth } from "../../../context/AuthContext";

interface AdditionalSpeciesDataProps {
  benchmarkGroupId?: string;
}
export default function AdditionalSpeciesData({
  benchmarkGroupId,
}: AdditionalSpeciesDataProps) {
  const { id } = useParams<PathParams>();
  const { token } = useAuth();
  const groupId = benchmarkGroupId || id;
  const { responseData: additionalData } = useFetch<AdditionalSpeciesDataType>({
    url: `${REACT_APP_API_URL}/benchmark-groups/${groupId}/organization`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  if (!additionalData) {
    return <div>No additional data currently available</div>;
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={6}>
        <List dense>
          <ListItem>
            <ListItemText
              primary={`Organization: ${additionalData.organization_name}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Group: ${additionalData.benchmark_group}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Total farms: ${additionalData.total_farms}`}
            />
          </ListItem>
          {/* <ListItem>
            <ListItemText
              primary={`Recording period: ${additionalData.recording_period}`}
            />
          </ListItem> */}
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <List dense>
          {/* <ListItem>
            <ListItemText
              primary={`Number of farms with red list species: ${additionalData.farms_red_list_species}`}
            />
          </ListItem> */}
          <ListItem>
            <ListItemText
              primary={`Farms providing species diversity data only: ${additionalData.farms_limited_access}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Farms providing full data access: ${additionalData.farms_full_access}`}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}
