import React, { ChangeEvent, useState } from "react";

import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell/TableCell";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableBody from "@mui/material/TableBody/TableBody";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button/Button";
import Typography from "@mui/material/Typography/Typography";
import { OutlinedInput, InputAdornment, TableSortLabel } from "@mui/material";
import WebSiteLink from "@mui/material/Link";
import SearchIcon from "@mui/icons-material/Search";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useFetch from "../../service/useFetch";
import { useAuth } from "../../context/AuthContext";
import {
  OrganizationDataResponseType,
  Order,
  PaginationWithSort,
} from "../../types/DataTypes";
import { TABLE_HEADERS_ORGANIZATION } from "../../constants/tableConstants";
import useDebounce from "../../hooks/useDebounce";
import "../../styles/tableCell.css";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { handleNavigateWebsite } from "../../utils/handleNavigateWebsite";
import { userRole } from "../../constants/userRoleConstants";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { OrderEnum } from "../../constants/enums";
import { highlightText } from "../../utils/highlightText";

function OrganizationListPage() {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<string>("");
  const [order, setOrder] = useState<Order>(OrderEnum.ASC);
  const [sort, setSort] = useState<string>("");
  const { token, user } = useAuth();
  const navigate = useNavigate();

  const { responseData, loading, error } = useFetch<
    PaginationWithSort<OrganizationDataResponseType>
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/organization?page=${page}&limit=${rowsPerPage}&sort_by=${sort}&order=${order}&name=${search}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  useDebounce(() => {
    setSearch(debouncedValue);
  }, debouncedValue);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setDebouncedValue(event.target.value);
    setPage(0);
  };

  const handleSort = (sortName: string) => {
    setSort(sortName);
    setOrder(order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC);
  };

  const handleNavigate = (id: string) => {
    navigate(`/organization/${id}`);
  };

  const handleNavigateAdd = () => {
    navigate("/organization/create");
  };

  return (
    <Container>
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <BackComponent />
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error}
          </Typography>
        </Box>
      )}
      <Grid container spacing={0} direction="column">
        <Grid container>
          <Grid item pb={2} xs={12} md={4}>
            <OutlinedInput
              fullWidth
              id="search"
              name="search"
              placeholder="Search organization..."
              type="text"
              size="small"
              onChange={handleSearch}
              value={debouncedValue}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          {user?.role &&
            {
              [userRole.admin]: (
                <Grid
                  item
                  pb={2}
                  xs={12}
                  md={8}
                  display="flex"
                  justifyContent="end"
                >
                  <Button
                    sx={{ width: { md: "50%" } }}
                    type="button"
                    size="medium"
                    variant="contained"
                    onClick={handleNavigateAdd}
                  >
                    Add organization
                  </Button>
                </Grid>
              ),
            }[user.role]}
        </Grid>
        <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
          <TableContainer component={Paper}>
            <Box sx={{ overflow: "auto" }}>
              <Box
                sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
              >
                <Table sx={{ minWidth: 600 }} aria-label="table">
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADERS_ORGANIZATION.map(({ sortName, label }) => (
                        <TableCell
                          key={label}
                          onClick={() => handleSort(sortName)}
                          sx={{ cursor: "pointer", paddingLeft: "100px" }}
                          align="left"
                        >
                          {label}
                          {sortName && (
                            <TableSortLabel
                              active={sort === sortName}
                              direction={sort === sortName ? order : "asc"}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && !responseData && (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {responseData?.results.map(
                      (data: OrganizationDataResponseType) => (
                        <TableRow
                          hover
                          sx={{
                            opacity: data.is_active === false ? "0.38" : "none",
                            cursor: "pointer",
                            "&.MuiTableRow-root:hover": {
                              backgroundColor: "rgba(206, 133, 20, 0.12)",
                            },
                          }}
                          key={data.id}
                        >
                          <TableCell
                            onClick={() => handleNavigate(data.id)}
                            sx={{ paddingLeft: "100px" }}
                            align="left"
                          >
                            {highlightText(data.name, search)}
                          </TableCell>
                          <TableCell sx={{ paddingLeft: "100px" }} align="left">
                            <WebSiteLink
                              onClick={() =>
                                handleNavigateWebsite(data.website_url)
                              }
                            >
                              {data.website_url}
                            </WebSiteLink>
                          </TableCell>
                          <TableCell
                            onClick={() => handleNavigate(data.id)}
                            sx={{ paddingLeft: "100px" }}
                            align="left"
                          >
                            {dayjs(data.created_at).format("DD/MM/YYYY")}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                  <PaginationComponent
                    count={responseData?.metadata.count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </Table>
              </Box>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
export default OrganizationListPage;
