import * as Yup from "yup";

export const validationSchemaSpecies = Yup.object({
  english: Yup.string().required("English name is required"),
  international: Yup.string().required("International name is required"),
  habitat: Yup.string().required("Habitat is required"),
  tropic: Yup.string().required("Trophic is required"),
  song_url: Yup.string().optional(),
  rating: Yup.string().optional(),
  trophic_level: Yup.number()
    .max(5, "Thropic level must be between 1 and 5")
    .min(1, "Thropic level must be between 1 and 5"),
  bio_indicators: Yup.array().optional(),
  food_sources: Yup.array().optional(),
  assemblages: Yup.array().optional(),
});
