import { useNavigate } from "react-router";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { Chip, Divider, Grid } from "@mui/material";
import {
  SpeciesComparisonReportDataType,
  SpeciesReportType,
} from "../../../types/DataTypes";

Chart.register(CategoryScale);

export default function SpeciesComparisonBarChart({
  reportData,
  label1,
  label2,
}: {
  reportData: SpeciesComparisonReportDataType;
  label1: string;
  label2: string;
}) {
  const navigate = useNavigate();
  const commonValue = reportData.commonSpecies.length;

  const chartHeight = Math.max(
    reportData.datasetA.species.length,
    reportData.datasetB.species.length,
    20 // In case there is no data we need default value
  );
  const data = {
    labels: [
      `${label1}`,
      `Only in ${label1}`,
      "Common",
      `Only in ${label2}`,
      `${label2}`,
    ],
    datasets: [
      {
        label: "Species Count",
        data: [
          [0, reportData.datasetA.species.length],
          [commonValue, reportData.datasetA.onlyInSpecies.length + commonValue],
          [0, commonValue],
          [commonValue, reportData.datasetB.onlyInSpecies.length + commonValue],
          [0, reportData.datasetB.species.length],
        ],
        backgroundColor: ["#FFD96D"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context: { datasetIndex: number; dataIndex: number }) => {
            const speciesData: { [key: number]: { [key: number]: string } } = {
              0: {
                0: `${reportData.datasetA.species.length} species`,
                1: `${reportData.datasetA.onlyInSpecies.length} species`,
                2: `${reportData.commonSpecies.length} species`,
                3: `${reportData.datasetB.onlyInSpecies.length} species`,
                4: `${reportData.datasetB.species.length} species`,
              },
            };
            return speciesData[context.datasetIndex][context.dataIndex] || "";
          },
        },
      },
      customText: {
        id: "customText",
        beforeDraw(chart: any) {
          const {
            ctx,
            data,
            chartArea: { top, bottom, left, right },
            scales: { x, y },
          } = chart;
          ctx.save();

          data.datasets[0].data.forEach((value: any, index: number) => {
            if (Array.isArray(value) && value[1] === 0) {
              const xPos = x.getPixelForValue(index);
              const yPos = y.getPixelForValue(chartHeight / 2);
              ctx.font = "18px Arial";
              ctx.fillStyle = "#FF0000";
              ctx.textAlign = "center";
              ctx.save();
              ctx.translate(xPos, yPos);
              ctx.rotate(-Math.PI / 2);
              ctx.fillText("Insufficient data available", 20, 0);
              ctx.restore();
            }
          });
          ctx.restore();
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: chartHeight + 10,
      },
    },
    onHover: (event: any, chartElement: any) => {
      const target = event.native ? event.native.target : event.target;
      target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event: any, chartElement: any) => {
      if (chartElement.length > 0) {
        const { datasetIndex } = chartElement[0];
        const dataIndex = chartElement[0].index;

        let speciesToSend: SpeciesReportType[] = [];
        const label = data.labels[dataIndex];
        if (datasetIndex === 0) {
          switch (dataIndex) {
            case 0:
              speciesToSend = reportData.datasetA.species;
              break;
            case 1:
              speciesToSend = reportData.datasetA.onlyInSpecies;
              break;
            case 2:
              speciesToSend = reportData.commonSpecies;
              break;
            case 3:
              speciesToSend = reportData.datasetB.onlyInSpecies;
              break;
            case 4:
              speciesToSend = reportData.datasetB.species;
              break;
            default:
              break;
          }
        }
        navigate("/species-list-card", {
          state: { species: speciesToSend, label: label.toString() },
        });
      }
    },
  };

  return (
    <div style={{ position: "relative", width: "80%", margin: "0 auto" }}>
      <Grid item sm={12} md={12} lg={12}>
        <Divider>
          <Chip color="primary" label="Species Comparison" />
        </Divider>
      </Grid>
      <Grid
        width="100%"
        marginTop={1}
        style={{
          position: "relative",
          width: "100%",
          minHeight: "400px",
        }}
      >
        <Bar
          data={data}
          options={options}
          plugins={[options.plugins.customText]}
        />
      </Grid>
    </div>
  );
}
