import GroupIcon from "@mui/icons-material/Group";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import GroupsIcon from "@mui/icons-material/Groups";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ListIcon from "@mui/icons-material/List";

import BenchmarkGroupsIcon from "../components/icon/BenchmarkGroupsIcon";
import { MenuItems } from "../types/DataTypes";

export const COMPONENTS: MenuItems = [
  {
    textByRole: { default: "Users" },
    icon: <GroupIcon />,
    path: "/users",
    allowed: ["ADMIN"],
  },
  {
    textByRole: { FARM_ADMIN: "My Farm", default: "Farms" },
    icon: <AgricultureIcon />,
    path: "/farms",
    allowed: ["ADMIN", "FARM_ADMIN"],
  },
  {
    textByRole: {
      default: "Organizations",
      ORGANIZATION_ADMIN: "My Organization",
    },
    icon: <GroupsIcon />,
    path: "/organization",
    allowed: ["ADMIN", "ORGANIZATION_ADMIN"],
  },
  {
    textByRole: { default: "Reports" },
    icon: <SummarizeIcon />,
    path: "",
    allowed: ["*"],
    children: [
      {
        textByRole: { default: "Farm Report" },
        icon: <SummarizeIcon />,
        path: "/reports/farm-report",
        allowed: ["*"],
      },
      {
        textByRole: { default: "Benchmark Report" },
        icon: <SummarizeIcon />,
        path: "/reports/benchmark-report",
        allowed: ["*"],
      },
      {
        textByRole: { default: "Site to Site Report" },
        icon: <SummarizeIcon />,
        path: "/reports/site-to-site-report",
        allowed: ["*"],
      },
      {
        textByRole: { default: "Year to Year Report" },
        icon: <SummarizeIcon />,
        path: "/reports/year-to-year-report",
        allowed: ["*"],
      },
    ],
  },
  {
    textByRole: { default: "Species" },
    icon: <ListIcon />,
    path: "/species",
    allowed: ["*"],
  },

  {
    textByRole: { default: "Benchmark groups" },
    icon: <BenchmarkGroupsIcon />,
    path: "/benchmark-groups",
    allowed: ["ADMIN", "ORGANIZATION_ADMIN"],
  },
  {
    textByRole: { default: "Share requests" },
    icon: <ScreenShareIcon />,
    path: "/share-requests",
    allowed: ["*"],
  },
  {
    textByRole: { default: "Retailers" },
    icon: <ShoppingCartIcon />,
    path: "/placeholderpath",
    allowed: ["*"],
    disabled: true,
  },
  {
    textByRole: { default: "Processors" },
    icon: <StoreIcon />,
    path: "/placeholderpath",
    allowed: ["*"],
    disabled: true,
  },
];
