export function sortOptionsByLabel<T>(
  options: readonly T[],
  getOptionLabel?: (option: T) => string
): T[] {
  return [...options].sort((a, b) => {
    const labelA = getOptionLabel
      ? getOptionLabel(a)
      : (a as unknown as string);
    const labelB = getOptionLabel
      ? getOptionLabel(b)
      : (b as unknown as string);
    return labelA.localeCompare(labelB);
  });
}

type SortableItem = { [key: string]: any };

export function sortOptionsByKey<T extends SortableItem>(
  options: T[],
  key: keyof T
) {
  return [...options].sort((a, b) => a[key].localeCompare(b[key]));
}
