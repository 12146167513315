import useFetch from "../../service/useFetch";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { useAuth } from "../../context/AuthContext";
import "./Version.css";

function Version() {
  const webVersion = process.env.REACT_APP_VERSION;
  const { token } = useAuth();
  const { responseData: apiVersion } = useFetch<string>({
    url: `${REACT_APP_API_URL}/api-version`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  return (
    <footer className="version-footer">
      Api version: {apiVersion} Web version: {webVersion}
    </footer>
  );
}

export default Version;
