import { Box, Container, Grid } from "@mui/material";
import { useState } from "react";
import { useLocation, useParams } from "react-router";
import ReportCard from "../../components/cards/ReportCard";
import SpeciesListCard from "./SpeciesListCard";
import {
  ChirupRatingResponseType,
  FarmDetailsResponseType,
  ReportFarmDataType,
  TrendType,
} from "../../types/DataTypes";
import BackComponent from "../../components/back/BackComponent";

function SpeciesListCardPage() {
  const location = useLocation();
  const { species, label } = location.state || {};
  const [visible, setVisible] = useState(10);
  return (
    <Container>
      <BackComponent />
      <Grid item xs={12} md={12}>
        <ReportCard title={`Species list from ${label}`}>
          <Box display="flex" justifyContent="center">
            <SpeciesListCard
              data={{
                site_details: {} as FarmDetailsResponseType,
                farm: {} as ReportFarmDataType,
                report: {
                  species,
                  from: "",
                  to: "",
                },
                chirrup_rating: {} as ChirupRatingResponseType,
                trend: {} as TrendType,
              }}
              visible={visible}
              setVisible={setVisible}
            />
          </Box>
        </ReportCard>
      </Grid>
    </Container>
  );
}

export default SpeciesListCardPage;
