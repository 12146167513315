export const highlightText = (text: string, search: string) => {
  if (!search) return text;

  const regex = new RegExp(`(${search})`, "gi");
  const parts = text.split(regex);

  return parts.map((part, index) => {
    const uniqueKey = `${part}-${index}`;
    return regex.test(part) ? (
      <span
        key={uniqueKey}
        style={{
          backgroundColor: "#CE8514",
          fontWeight: "bold",
          color: "white",
        }}
      >
        {part}
      </span>
    ) : (
      <span key={uniqueKey}>{part}</span>
    );
  });
};
