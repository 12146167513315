import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  FormHelperText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Box,
} from "@mui/material";
import { FormikProps, FormikProvider, useFormik } from "formik";
import { SELECT_ITEMS } from "../../constants/selectItemConstants";
import InputFieldFormik from "./InputFieldFormik";
import { FarmData } from "../../types/DataTypes";
import { validationSchemaCreateFarm } from "../../validation/validationSchemaCreateFarm";
import { OrganizationSelectionObject } from "../../types/PropsTypes";
import useFetch from "../../service/useFetch";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { useAuth } from "../../context/AuthContext";
import { sortOptionsByKey } from "../../utils/sortOptions";

export default function FarmBasicInfoForm({
  initialValues,
  onSubmit,
  fundingOrganizations,
  setFundingOrganizations,
}: {
  initialValues: FarmData;
  onSubmit: (values: FarmData) => void;
  fundingOrganizations: string[]; // Add this prop type
  setFundingOrganizations: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const formik: FormikProps<FarmData> = useFormik<FarmData>({
    initialValues,
    onSubmit,
    validationSchema: validationSchemaCreateFarm,
  });

  const { token } = useAuth();

  const { responseData: responseDataAllOrganizations } = useFetch<
    OrganizationSelectionObject[]
  >({
    url: `${REACT_APP_API_URL}/organization/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const handleChangeOrganization = (
    event: any,
    newValue: OrganizationSelectionObject[]
  ) => {
    const selectedOrganizationIds = newValue.map((org) => org.id);
    setFundingOrganizations(selectedOrganizationIds);
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            textAlign: "center",
          }}
        >
          <InputFieldFormik name="name" label="Farm name*" />

          <InputFieldFormik name="CPH_number" label="CPH number" />
          <InputFieldFormik name="SBI_number" label="SBI number" />
          <InputFieldFormik name="address" label="Address*" />

          <Box>
            <Autocomplete
              size="small"
              id="county"
              value={SELECT_ITEMS.find(
                (county) => county.value === formik.values.county
              )}
              options={sortOptionsByKey(SELECT_ITEMS, "label")}
              getOptionLabel={(county) => county.label}
              onChange={(e, county) =>
                formik.setFieldValue("county", county?.value || "")
              }
              onBlur={formik.handleBlur("county")}
              onOpen={formik.handleBlur("county")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="county"
                  value={formik.values.county}
                  onBlur={formik.handleBlur("county")}
                  label="County*"
                />
              )}
            />
            {formik.touched.county && formik.errors.county && (
              <FormHelperText error>{formik.errors.county}</FormHelperText>
            )}
          </Box>
          <InputFieldFormik name="post_code" label="Post code*" />
          <Autocomplete
            size="small"
            id="organization"
            value={
              responseDataAllOrganizations?.filter((org) =>
                fundingOrganizations.includes(org.id)
              ) || []
            }
            options={responseDataAllOrganizations || []}
            multiple
            getOptionLabel={(organization) => organization.name ?? ""}
            onChange={handleChangeOrganization}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Funding organizations"
                placeholder="Select organizations"
              />
            )}
          />
          <FormControl>
            <FormLabel id="main_interest">
              What is this farm&apos;s main interest in bird biodiversity
              monitoring?*
            </FormLabel>
            <RadioGroup
              aria-labelledby="main_interest"
              value={formik.values.main_interest}
              name="main-interest-radiogroup"
              onChange={(event) =>
                formik.setFieldValue("main_interest", event.target.value)
              }
              onBlur={formik.handleBlur}
            >
              <FormControlLabel
                value="curious-about-birds-on-farm"
                control={<Radio />}
                label="Curious about birds on farm"
              />
              <FormControlLabel
                value="eco-productivity-indicator"
                control={<Radio />}
                label="Eco productivity indicator"
              />
              <FormControlLabel
                value="government-payments"
                control={<Radio />}
                label="Government payments"
              />
              <FormControlLabel
                value="sell-biodiversity-credits"
                control={<Radio />}
                label="Sell biodiversity credits"
              />
            </RadioGroup>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                id="check"
                name="is_active"
                checked={formik.values.is_active}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label="Farm is active"
          />
          <Button size="large" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </form>
    </FormikProvider>
  );
}
