import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { notify } from "../../utils/notify";
import {
  FarmData,
  PaginationWithSort,
  ShareRequestsResponseType,
} from "../../types/DataTypes";
import FarmBasicInfoForm from "./FarmBasicInfoForm";
import useFetch from "../../service/useFetch";
import BackComponent from "../../components/back/BackComponent";
import { ShareRequestStatus } from "../../constants/enums";

export default function FarmCreate() {
  const { token } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [fundingOrganizations, setFundingOrganizations] = useState<string[]>(
    []
  );

  const { responseData, loading } = useFetch<FarmData>({
    url: `${REACT_APP_API_URL}/farms/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/farms/${id}`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const { responseData: shareRequestData } = useFetch<
    PaginationWithSort<ShareRequestsResponseType>
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/share-requests/funding-requests/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  useEffect(() => {
    if (shareRequestData) {
      const organizations = shareRequestData.results.map(
        (request) => request.organization.id
      );
      setFundingOrganizations(organizations);
    }
  }, [shareRequestData]);

  const { fetchData: sendRequestData } = useMutate({
    url: `${REACT_APP_API_URL}/farms/${id}/share-request-funding-organizations`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const initialValues: FarmData = {
    name: responseData?.name || "",
    CPH_number: responseData?.CPH_number || "",
    SBI_number: responseData?.SBI_number || "",
    address: responseData?.address || "",
    county: responseData?.county || "",
    post_code: responseData?.post_code || "",
    is_active: responseData?.is_active || true,
    main_interest: responseData?.main_interest || "curious-about-birds-on-farm",
  };
  const onSubmit = (values: FarmData) => {
    const fundingOrganizationsData = {
      ids: fundingOrganizations,
    };
    Promise.all([fetchData(values), sendRequestData(fundingOrganizationsData)])
      .then(() => {
        notify("Farm successfully edited!");
        navigate(-1);
      })
      .catch((error) => {
        const errorMessage =
          error.message === "Share request already exists"
            ? `Something went wrong: ${error.message}. Please contact admin.`
            : `Something went wrong: ${error.message}`;
        notify(`${errorMessage}`, error);
      });
  };

  return (
    <>
      <BackComponent />
      <Card
        sx={{
          maxWidth: { md: "50vw" },
          ml: { md: "auto" },
          mr: { md: "auto" },
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="h5">Edit farm</Typography>
            <Box>
              <FarmBasicInfoForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                fundingOrganizations={fundingOrganizations}
                setFundingOrganizations={setFundingOrganizations}
              />
            </Box>
          </CardContent>
        )}
      </Card>
    </>
  );
}
