function checkWebPDecoding(): boolean {
  const elem = document.createElement("canvas");
  if (elem.getContext && elem.getContext("2d")) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }
  return false;
}
type WebPFeature = "lossy" | "lossless" | "alpha" | "animation";

function checkWebPFeature(feature: WebPFeature): Promise<boolean> {
  const kTestImages: Record<WebPFeature, string> = {
    lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
    lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    alpha:
      "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/" +
      "Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    animation:
      "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////" +
      "AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
  };

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const result = img.width > 0 && img.height > 0;
      resolve(result);
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = `data:image/webp;base64,${kTestImages[feature]}`;
  });
}

export function checkWebPSupport(feature: WebPFeature): Promise<boolean> {
  if (checkWebPDecoding()) {
    return Promise.resolve(true);
  }

  return checkWebPFeature(feature).then((isSupported) => {
    if (isSupported) {
      return true;
    }
    return false;
  });
}
